import Story from '@common/models/orm/Story'
import StoryRevision from '@common/models/orm/StoryRevision'
import ChatNode from '@common/models/orm/ChatNode'
import { createApiUrl } from '@common/plugins/helpers'

export default {
  namespaced: true,
  getters: {
    lastRevisionByStoryId: (state, { query }) => {
      return (storyId, status) => {
        const allRevisions = query()
          .where('storyId', (value) => value === storyId)
          .where('status', (value) =>
            [
              StoryRevision.STATUS_DRAFT,
              StoryRevision.STATUS_PUBLISHED,
            ].includes(value),
          )
          .withAll()
          .get()

        let publishedRevision = null
        let draftRevision = null

        allRevisions.forEach((version) => {
          if (version.status === StoryRevision.STATUS_PUBLISHED) {
            publishedRevision = version
          } else if (version.status === StoryRevision.STATUS_DRAFT) {
            draftRevision = version
          }
        })

        if (
          (publishedRevision &&
            draftRevision &&
            publishedRevision.updatedAt === draftRevision.updatedAt) ||
          (publishedRevision && status === StoryRevision.STATUS_PUBLISHED)
        ) {
          return publishedRevision
        }

        return draftRevision || publishedRevision
      }
    },
    currentPublished: (state, { query }) => {
      return (storyId, language) =>
        query()
          .where('storyId', (value) => value === storyId)
          .where('status', (value) => value === StoryRevision.STATUS_PUBLISHED)
          .withAll()
          .first()
    },
    currentDraft: (state, { query }) => {
      return (storyId, language) =>
        query()
          .where('storyId', (value) => value === storyId)
          .where('status', (value) => value === StoryRevision.STATUS_DRAFT)
          .withAll()
          .first()
    },
    getByStoryId: (state, { query }) => {
      return (storyId) =>
        query()
          .where('storyId', (value) => value === storyId)
          .orderBy('updatedAt', 'desc')
          .withAll()
          .get()
    },
  },
  actions: {
    async fetchById({ dispatch }, id) {
      await dispatch(
        'fetchByModelId',
        { StoryRevision, id },
        {
          root: true,
        },
      )
    },
    async fetchByStoryId({ dispatch }, id) {
      const url = createApiUrl(Story.entity, id, StoryRevision.entity)

      try {
        const { data: revisions } = await this.$axios
          .get(url, {
            expectList: true,
          })
          .then(({ data }) => data)

        await this.$db().model(StoryRevision).insertOrUpdate({
          data: revisions,
        })
      } catch (error) {
        throw new Error(error)
      }
    },
    async saveChatNodes({ dispatch }, { id, nodes = [] }) {
      const url = createApiUrl(Story.entity, id, ChatNode.entity)
      const { data } = await this.$axios.$post(url, nodes)

      await this.$db().model(ChatNode).insertOrUpdate({
        data,
      })

      return data
    },
    async saveChatNode({ dispatch }, { storyId, node }) {
      const url = createApiUrl(Story.entity, storyId, ChatNode.entity)
      const { data } = await this.$axios.$post(url, node)

      await this.$db().model(ChatNode).insertOrUpdate({
        data,
      })

      return data
    },
    async updateChatNode({ dispatch }, { storyId, nodeVid, payload }) {
      const url = createApiUrl(
        Story.entity,
        storyId,
        'draft',
        ChatNode.entity,
        nodeVid,
      )

      const { data } = await this.$axios.$put(url, payload)

      await this.$db().model(ChatNode).insertOrUpdate({
        data,
      })

      return data
    },
    async moveChatNode({ dispatch }, { storyId, nodeVid, payload }) {
      const url = createApiUrl(
        Story.entity,
        storyId,
        'draft',
        ChatNode.entity,
        nodeVid,
      )

      const { data } = await this.$axios.$patch(url, payload)

      await this.$db().model(ChatNode).insertOrUpdate({
        data,
      })

      return data
    },
    async deleteChatNode({ dispatch }, { storyId, nodeVid, payload }) {
      const url = createApiUrl(
        Story.entity,
        storyId,
        'draft',
        ChatNode.entity,
        nodeVid,
      )

      await this.$axios.$delete(url, { data: payload })
    },
    async apiUpdateOrCreate({ dispatch }, formData) {
      const apiAction = formData.id ? this.$axios.$put : this.$axios.$post
      const url = createApiUrl(StoryRevision.entity, formData.id)
      const { data } = await apiAction(url, formData)

      if (!data) {
        return {}
      }

      const { [StoryRevision.entity]: entities } = await this.$db()
        .model(StoryRevision)
        .insertOrUpdate({
          data,
        })

      return entities.shift()
    },
    async apiPublish({ dispatch }, id) {
      const url = createApiUrl(Story.entity, id, 'publish')

      try {
        const { data: revisions } = await this.$axios
          .post(
            url,
            {},
            {
              expectList: true,
            },
          )
          .then(({ data }) => data)

        await this.$db().model(StoryRevision).deleteAll()
        await this.$db().model(StoryRevision).insertOrUpdate({
          data: revisions,
        })

        return revisions
      } catch (error) {
        throw new Error(error)
      }
    },
    async apiDeleteRevision({ dispatch }, id) {
      const url = createApiUrl(StoryRevision.entity, id)

      await this.$axios.$delete(url)
      await this.$db().model(StoryRevision).delete(id)
    },
  },
}
