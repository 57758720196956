<template>
  <main :class="sidebarClasses">
    <AppOutdatedBanner v-if="isAppOutdated" />

    <AppHeader
      :workspace="workspace"
      :user="user"
      :project="project"
      :is-on-project-page="isOnProject"
      class="layout-header"
    >
      <OrientationLocator
        :is-on-project="isOnProject"
        :projects="projects"
        :project="project"
        @navigate="onNavigate"
        @open="loadProjects"
      />
    </AppHeader>
    <AppSidebar
      class="layout-sidebar"
      :current-project="project"
      :plan="plan"
      :is-app-outdated="isAppOutdated"
    />
    <article
      class="app-container"
      :class="{ 'grid-overlay': $route.query.grid }"
    >
      <nuxt />
    </article>

    <portal-target name="modals" multiple />
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import User from '@common/models/orm/User'
import Workspace from '@common/models/orm/Workspace'
import Project from '@common/models/orm/Project'
import AppHeader from '@common/components/AppHeader'
import AppSidebar from '@common/components/AppSidebar'
import OrientationLocator from '@common/components/OrientationLocator'
import BillingFactory from '@common/models/BillingFactory'
import AppOutdatedBanner from '@common/components/AppOutdatedBanner'

export default {
  components: {
    AppHeader,
    OrientationLocator,
    AppSidebar,
    AppOutdatedBanner,
  },
  middleware: ['app-init'],
  data() {
    return {
      relationsLoaded: false,
    }
  },
  head() {
    return {
      htmlAttrs: {
        class: 'hide-body-overflow',
      },
    }
  },
  computed: {
    ...mapGetters({
      isSidebarOpen: 'layout/isSidebarOpen',
      isAppOutdated: 'env-config/isAppOutdated',
    }),
    user() {
      return this.$store.$db().model(User).getters('current')
    },
    workspace() {
      return this.$store.$db().model(Workspace).getters('current')
    },
    projects() {
      return this.$store.$db().model(Project).getters('getAll')
    },
    project() {
      return this.$store.$db().model(Project).getters('current')
    },
    plan() {
      if (!this.project) {
        return BillingFactory.getDefaultPlan().entity
      }

      return this.project.billingPlan
    },
    isOnProject() {
      return !!this.$route?.fullPath.includes('project')
    },
    sidebarClasses() {
      return [
        this.isAppOutdated
          ? 'layout-with-sidebar-and-banner'
          : 'layout-with-sidebar',
        this.isSidebarOpen && 'is-expanded',
      ]
    },
  },
  methods: {
    async loadProjects() {
      if (this.relationsLoaded) {
        return
      }

      try {
        await this.$store
          .$db()
          .model(Workspace)
          .dispatch('fetchRelations', this.workspace.id)
        this.relationsLoaded = true
      } catch (err) {}
    },
    onNavigate({ id }) {
      this.$router.push(
        this.localePath({
          name: 'project-id',
          params: {
            id,
          },
        }),
      )
    },
  },
}
</script>
