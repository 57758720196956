import { render, staticRenderFns } from "./ModalDialog.vue?vue&type=template&id=797c5625&scoped=true"
import script from "./ModalDialog.vue?vue&type=script&lang=js"
export * from "./ModalDialog.vue?vue&type=script&lang=js"
import style0 from "./ModalDialog.vue?vue&type=style&index=0&id=797c5625&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../chatbot/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "797c5625",
  null
  
)

export default component.exports