<template>
  <div class="nuxt-error">
    <component
      :is="errorPage"
      :error="error"
      :is-authenticated="isAuthenticated"
    />
  </div>
</template>

<script>
import Error404 from '@common/components/error/Error404'
import ErrorFallback from '@common/components/error/ErrorFallback'

export default {
  name: 'NuxtError',
  components: {
    Error404,
  },
  layout(context) {
    return context.$auth.loggedIn ? 'default' : 'unauthenticated'
  },
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  head() {
    return {
      title: this.message,
      bodyAttrs: {
        class: ['bg-primary-radial-gradient'].join(' '),
      },
    }
  },
  computed: {
    statusCode() {
      return (this.error && this.error.statusCode) || 500
    },
    message() {
      return this.error.message
    },
    errorPage() {
      if (this.statusCode === 404) {
        return Error404
      }

      return ErrorFallback
    },
    isAuthenticated() {
      return this.$auth.loggedIn
    },
  },
}
</script>
