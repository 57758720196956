<template>
  <main class="main">
    <AppOutdatedBanner v-if="isAppOutdated" />

    <AppHeader
      :user="user"
      :workspace="workspace"
      :project="project"
      :is-on-project-page="isOnProject"
    >
      <OrientationLocator type="is-primary" @workspace="onWorkspaceChange" />
    </AppHeader>
    <article class="app-container">
      <nuxt />
    </article>
    <EmailVerificationModal
      v-if="hasEmailPending"
      :value="hasEmailPending"
      :email="user.pendingEmail"
      @resend="onResendEmail"
      @refresh="onRefreshUser"
    />
    <portal-target name="modals" multiple />
  </main>
</template>

<script>
import { mapGetters } from 'vuex'

import UiNotifications from '@common/mixins/UiNotifications'

import AppHeader from '@common/components/AppHeader'
import User from '@common/models/orm/User'
import Workspace from '@common/models/orm/Workspace'
import Project from '@common/models/orm/Project'
import OrientationLocator from '@common/components/OrientationLocator'
import AppOutdatedBanner from '@common/components/AppOutdatedBanner'

import EmailVerificationModal from '@/components/modals/EmailVerificationModal'

export default {
  components: {
    AppHeader,
    AppOutdatedBanner,
    OrientationLocator,
    EmailVerificationModal,
  },
  mixins: [UiNotifications],
  middleware: ['app-init'],
  head() {
    return {
      htmlAttrs: {
        class: 'hide-body-overflow',
      },
    }
  },
  computed: {
    ...mapGetters({
      isAppOutdated: 'env-config/isAppOutdated',
    }),
    user() {
      return this.$store.$db().model(User).getters('current')
    },
    workspace() {
      return this.$store.$db().model(Workspace).getters('current')
    },
    project() {
      return this.$store.$db().model(Project).getters('current')
    },
    isOnProject() {
      return !!this.$route?.fullPath.includes('project')
    },
    hasEmailPending() {
      return !!this.user?.pendingEmail && !this.user?.email
    },
  },
  watch: {
    // NOTE if we dont want email checking in this high-level file, we should move it to a smart component
    hasEmailPending(val, old) {
      if (old && !val) {
        this.notify(
          this.$t('register.email_verification.notification', {
            email: this.user.email,
          }),
        )
      }
    },
  },
  methods: {
    async onResendEmail() {
      try {
        await this.$store
          .$db()
          .model(User)
          .dispatch('resendEmailConfirmation', this.user.pendingEmail)
        this.notify(this.$t('general.email.resend'))
      } catch (error) {
        this.errorNotice(error)
      }
    },
    async onRefreshUser() {
      await this.$store.$db().model(User).dispatch('fetchMe')
    },
    async onWorkspaceChange(value) {
      localStorage.setItem('current_workspace', value.id)

      await this.$store.$db().model(Workspace).dispatch('setCurrent', value.id)

      this.$router.push({ name: 'workspace-id', params: { id: value.id } })
    },
  },
}
</script>
