<template>
  <ModalDialog
    v-if="visible"
    v-model="showModal"
    v-bind="$attrs"
    @closeModal="onClose"
  >
    <div v-if="!isDelete" class="flex-hv-centered story-icon-container">
      <MessagesBubbleSquare class="story-icon-size" />
    </div>
    <h2 class="title has-text-centered" v-html="title" />
    <p v-if="subtitle" class="subtitle has-text-centered" v-html="subtitle" />
    <hr v-show="!isDelete" class="separator-light" />

    <StoryForm
      v-if="!isDelete"
      class="has-control-offset"
      :value="entity"
      :relation-id="entityRelationId"
      @close="onClose"
      @entityUpdated="onUpdated"
      @entityCreated="onCreated"
      @groupChanged="onGroupChanged"
    >
      <template slot="submit" slot-scope="isLoadingClass">
        <div class="field">
          <div class="control">
            <b-button
              native-type="submit"
              type="is-primary"
              rounded
              class="is-wide"
              :class="isLoadingClass"
            >
              {{ isUpdate ? $t('cta.update') : $t('cta.save') }}
            </b-button>
          </div>
        </div>
      </template>
    </StoryForm>

    <DeleteEntityForm
      v-else
      :value="entity"
      :string-to-confirm="entity.name"
      class="has-control-offset"
      @success="onDeleted"
    />
  </ModalDialog>
</template>

<script>
import ModalDialog from '@common/components/modals/ModalDialog'
import UiNotifications from '@common/mixins/UiNotifications'
import Group from '@common/models/orm/Group'
import StoryForm from '@/components/forms/StoryForm'
import DeleteEntityForm from '@/components/forms/DeleteEntityForm'

export default {
  components: {
    ModalDialog,
    DeleteEntityForm,
    StoryForm,
    MessagesBubbleSquare: () =>
      import('~common/assets/inline/messages-bubble-square.svg'),
  },
  mixins: [UiNotifications],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: Object,
      required: true,
    },
    entityRelationId: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
    isDelete: {
      type: Boolean,
      default: false,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: this.visible,
      selectedGroup: null,
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler(visible) {
        this.$nextTick(() => {
          this.showModal = visible
        })
      },
    },
  },
  methods: {
    onClose($event) {
      this.showModal = false
      this.$emit('close', $event)
    },
    onUpdated($event) {
      this.showModal = false
      this.$emit('update', $event)
      this.entityUpdatedNotice(this.$tc('general.story'))
    },
    async onCreated($event) {
      this.showModal = false

      if (this.selectedGroup === null) {
        this.$emit('create', $event)

        return
      }

      const storyId = $event.id

      const payload = { ...this.selectedGroup }

      payload.items = [...payload.items, storyId]

      await this.$store
        .$db()
        .model(Group)
        .dispatch('apiUpdate', {
          ...payload,
        })

      this.$emit('create', $event)
      // notify after page load
    },
    onDeleted($event) {
      this.showModal = false
      this.$emit('delete', $event)
    },
    onGroupChanged(groupInfo) {
      this.selectedGroup = groupInfo
    },
  },
}
</script>
<style lang="scss">
.story-icon-container {
  margin: $sp-20;

  .story-icon-size {
    height: $sp-64;
    width: auto;
    color: $blue-500;
  }
}
.title-has-margin {
  margin-bottom: $sp-40 !important;
}
</style>
