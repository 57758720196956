import ChatbotExtension from '@/models/orm/ChatbotExtension'

const INTERNAL_TRIAGE_PARAMETER = 'slack-authentication'
const CODE_PARAMETER = 'code'
const STATE_PARAMETER = 'state'
const ERROR_PARAMETER = 'error'
const CLIENT_ID_PARAMETER = 'client_id'
const SCOPE_PARAMETER = 'scope'
const REDIRECT_URI_PARAMETER = 'redirect_uri'

const defaultState = {}

export const state = () => defaultState

export const getters = {}

export const mutations = {}

export const actions = {
  // eslint-disable-next-line require-await
  async constructReturnUrl({ state }, url) {
    return new Promise((resolve) => {
      const parsedUrl = new URL(url)
      const queryParams = new URLSearchParams(parsedUrl.search)

      // Remove already existing / old slack response parameters that are still in the URL in case of a retry
      queryParams.delete(INTERNAL_TRIAGE_PARAMETER)
      queryParams.delete(STATE_PARAMETER)
      queryParams.delete(CODE_PARAMETER)
      queryParams.delete(ERROR_PARAMETER)

      // Add an additional parameter so we can triage the request properly
      queryParams.append(INTERNAL_TRIAGE_PARAMETER, '1')
      parsedUrl.search = queryParams.toString()

      resolve(parsedUrl.toString())
    })
  },
  // eslint-disable-next-line require-await
  async constructOAuthUrl({ state }, { redirectUri, clientId, scope }) {
    return new Promise((resolve) => {
      const url = new URL(process.env.SLACK_OAUTH_URL)
      const queryParams = new URLSearchParams(url.search)

      queryParams.append(CLIENT_ID_PARAMETER, clientId)
      queryParams.append(SCOPE_PARAMETER, scope)
      queryParams.append(REDIRECT_URI_PARAMETER, redirectUri)
      url.search = queryParams.toString()

      resolve(url.toString())
    })
  },
  // eslint-disable-next-line require-await
  async hasAuthenticationResponse({ state }, params = {}) {
    return params[INTERNAL_TRIAGE_PARAMETER]
  },
  async handleAuthentication(
    { state, dispatch },
    { params = {}, projectId, redirectUri },
  ) {
    if (params[ERROR_PARAMETER]) {
      dispatch('handleAuthenticationError', params[ERROR_PARAMETER])
    } else {
      await dispatch('handleAuthenticationSuccess', {
        code: params[CODE_PARAMETER],
        authState: params[STATE_PARAMETER],
        projectId,
        redirectUri,
      })
    }
  },

  async handleAuthenticationSuccess(
    { state },
    { code, authState, projectId, redirectUri },
  ) {
    await ChatbotExtension.dispatch('createExtension', {
      type: 'slack',
      projectId,
      extensionData: {
        code,
        state: authState,
        redirectUri,
      },
    })
  },
  handleAuthenticationError({ state }, error) {
    // eslint-disable-next-line no-console
    console.error('User did not authorise Slack integration:', error)
  },
  async removeAuthentication({ state }, extensionId) {
    await ChatbotExtension.dispatch('deleteExtension', extensionId)
  },
  // eslint-disable-next-line require-await
  async removeAuthenticationUrlParams({ state }, params) {
    return new Promise((resolve) => {
      const query = { ...params }

      delete query[INTERNAL_TRIAGE_PARAMETER]
      delete query[CODE_PARAMETER]
      delete query[STATE_PARAMETER]
      delete query[ERROR_PARAMETER]

      resolve(query)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
