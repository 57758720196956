import stateSetters from '@common/plugins/state-setters'
import stateGetters from '@common/plugins/state-getters'
import ChatNode from '@common/models/orm/ChatNode'
import Story from '@common/models/orm/Story'
import StoryRevision from '@common/models/orm/StoryRevision'
import Project from '@common/models/orm/Project'
import Trigger from '@common/models/orm/Trigger'
import ErrorHandler from '@common/models/ErrorHandler'
import NodeGraph from '@/models/NodeGraph'
import ChatElementFactory from '@/models/ChatElementFactory'

const defaultState = {
  loading: false,
  storyId: '',
  currentRevision: {},
  nodes: [],
  graph: null,
  editing: false,
  isEditingModifiers: false,
  shadowGraph: null, // shadow copy of graph
  activeNodeVid: '',
  newNode: null,
  draggedItem: null,
  sidebarOpen: false,
  enableDropdownAutoClose: true,
}

const ACTION_DELETE_NODE = 'delete'
const ACTION_INSERT_NODE = 'insert'
const ACTION_UPDATE_NODE = 'update'
const ACTION_MOVE_NODE = 'move'

export const state = () => defaultState

export const mutations = {
  ...stateSetters(defaultState),
  reset(state) {
    // Work around Vue reactivity as state = {...defaultState } does somehow not correctly
    // trigger a state change (eg when run from dispatch)
    Object.entries(defaultState).forEach(([k, v]) => {
      state[k] = v
    })
  },
  resetShadowGraph(state) {
    state.shadowGraph = null
  },
  initShadowGraph(state) {
    if (!state.graph) {
      throw new Error('An Error occurred. Please reload the page.')
    }

    state.shadowGraph = state.graph.clone()
  },
  saveShadowGraph(state) {
    state.graph = state.shadowGraph
    state.shadowGraph = null
  },
  deleteNode(state, { vid, deleteAllChildren }) {
    state.graph.deleteNode(vid, deleteAllChildren)
  },
  insertNode(state, node) {
    state.graph.insertNode(node)
  },
  moveNode(state, { vid, parentVid, weight }) {
    state.graph.moveNode(vid, parentVid, weight)
  },
  updateNode(state, { vid, payload, modifiers, variableId }) {
    state.graph.updateNode(vid, payload, modifiers, variableId)
  },
  setNodeId(state, { node, id }) {
    state.graph.setNodeId(node, id)
  },
  // TODO we should differentatiate naming conventions when affecting shadowGraph
  setActiveNodeChildWeight(state, weight) {
    if (!state.activeNodeVid) {
      return
    }

    state.shadowGraph.setActiveChildWeightByVid(state.activeNodeVid, +weight)
  },
  removeActiveNodeChildrenByChildWeight(state, weight) {
    state.shadowGraph.removeChildrenByWeight(state.activeNodeVid, +weight)
  },
  switchActiveNodeChildrenByWeight(state, { oldIndex, newIndex }) {
    state.shadowGraph.switchChildrenByWeight(
      state.activeNodeVid,
      oldIndex,
      newIndex,
    )
  },
  setActiveChildWeightByVid(state, { vid, weight }) {
    state.graph.setActiveChildWeightByVid(vid, weight)
  },
  setNewNode(state, data) {
    state.newNode = new ChatNode(data)
  },
  resetNewNode(state) {
    state.newNode = null
  },
  softValidate(state) {
    state.graph.softValidateNodes()
  },
  revealActivePathWithError(state) {
    state.graph.revealActivePathWithError()
  },
  setDropdownAutoClose(state) {
    state.enableDropdownAutoClose = !state.enableDropdownAutoClose
  },
}

export const getters = {
  ...stateGetters(defaultState),
  activeNode(state) {
    if (state.newNode) {
      return state.newNode
    }

    if (state.activeNodeVid && state.graph) {
      return state.graph.getNodeByVid(state.activeNodeVid, false)
    }

    return null
  },
  isNew(state) {
    return state.currentRevision.status === StoryRevision.STATUS_PUBLISHED
  },
  activeNodeGraph(state) {
    return state.graph
  },
  nodesInActivePath(state) {
    if (state.graph) {
      const nodes = state.graph.getNodesInActivePath()

      // AI-299 disabled at the moment
      // // this is more performant than calling .getNodesByParentVid()
      // const nodesById = nodes.reduce((acc, v) => {
      //   acc[v.vid] = v

      //   return acc
      // }, {})

      // nodes.forEach((node) => {
      //   if (!node.parentVid) {
      //     return
      //   }

      //   const parentDecisionBlock = activeNodeGraph.getParentDecisionBlock(
      //     node.parentVid,
      //     nodesById,
      //   )

      //   if (parentDecisionBlock) {
      //     const { payloadType, activeChildWeight } = parentDecisionBlock

      //     node.decisionPathColor = activeNodeGraph.getDecisionBlockColor(
      //       payloadType,
      //       activeChildWeight,
      //     )
      //   }
      // })

      return nodes
    }

    return []
  },
  lastNodeOfActivePath(state) {
    if (state.graph) {
      return state.graph.getLastNodeOfActivePath()
    }

    return null
  },
  nodeValidationErrors(state) {
    if (state.graph) {
      return state.graph.errors
    }

    return []
  },
  storyBuilderErrors: (state, getters, rootState, rootGetters) => {
    const currentProject = rootGetters['entities/projects/current']

    if (!currentProject || !state.graph) {
      return []
    }

    const storyLinkTargets = rootState.entities['storylink-target'].data

    const workflows =
      rootGetters['entities/workflows/getByProjectId'](currentProject.id) || []

    const errors = [...(state.graph.errors ?? [])]

    const stories = currentProject.stories.map((story) => story.id)

    // substory and story link
    state.graph.nodes.forEach((node) => {
      const storyId = node.payload.storyId
      const storyLinkInfo = storyLinkTargets[storyId]

      const gotoInfo = storyLinkInfo?.gotos.find(
        (item) => item.value === node.payload.chatNodeVid,
      )

      switch (node.payloadType) {
        case 'story-link':
          if (stories.includes(storyId) === false) {
            errors.push({
              vid: node.vid,
              label: 'general.chat_element_story_link',
              error: 'error.notifications.storyLink.item.errorText',
            })
          } else if (node.payload.chatNodeVid && gotoInfo === undefined) {
            errors.push({
              vid: node.vid,
              label: 'general.chat_element_story_link',
              error: 'error.notifications.storyLink.item.gotoTarget.errorText',
            })
          }

          break

        case 'substory':
          if (stories.includes(storyId) === false) {
            errors.push({
              vid: node.vid,
              label: 'general.chat_element_sub_story',
              error: 'error.notifications.storyLink.item.errorText',
            })
          } else if (node.payload.chatNodeVid && gotoInfo === undefined) {
            errors.push({
              vid: node.vid,
              label: 'general.chat_element_sub_story',
              error: 'error.notifications.storyLink.item.gotoTarget.errorText',
            })
          }

          break
      }
    })

    // workflows
    state.graph.nodes.forEach((node) => {
      if (node.payloadType !== 'workflow') {
        return
      }

      const _workflow = workflows.find(
        (workflow) => workflow.id === node.payload.id,
      )

      if (_workflow === undefined || !_workflow.workflowId) {
        errors.push({
          vid: node.vid,
          label: 'general.chat_element_workflow',
          error: 'error.notifications.workflow.item.errorText',
        })
      }
    })

    return errors ?? []
  },
}

export const actions = {
  getErrorByNodeVid({ state }, vid) {
    return state.nodes.errors.find((item) => item.vid === vid)
  },
  async autoSaveInsertNode({ dispatch, commit }, node) {
    const error = await dispatch('autosaveStory', {
      action: ACTION_INSERT_NODE,
      payload: node,
    })

    if (!error) {
      return
    }

    commit('deleteNode', {
      vid: node.vid,
      deleteAllChildren: false,
    })
  },
  async insertNode({ state, dispatch, commit }, data) {
    const node = new ChatNode({
      ...data,
      storyRevisionId: state.currentRevision.id,
    })

    commit('insertNode', node)

    await dispatch('autoSaveInsertNode', node)
  },
  async updateActiveNode(
    { state, dispatch, commit },
    { payload, modifiers, variableId },
  ) {
    const data = {
      vid: state.activeNodeVid,
      payload,
      modifiers,
      variableId,
    }

    commit('updateNode', data)

    const error = await dispatch('autosaveStory', {
      action: ACTION_UPDATE_NODE,
      vid: state.activeNodeVid,
      payload: { payload, modifiers, variableId },
    })

    if (!error) {
      return
    }

    commit('deleteNode', {
      vid: data.vid,
      deleteAllChildren: false,
    })
  },
  async moveNode({ commit, dispatch, state }, move) {
    const parentVid = state.graph.getNodeByVid(move.vid).parentVid

    commit('moveNode', move)

    const error = await dispatch('autosaveStory', {
      action: ACTION_MOVE_NODE,
      vid: move.vid,
      payload: move,
    })

    if (!error) {
      return
    }

    // move the node back to it's original position.
    move.parentVid = parentVid

    commit('moveNode', move)
  },
  async deleteNode({ commit, dispatch }, data) {
    commit('deleteNode', data)

    await dispatch('autosaveStory', {
      action: ACTION_DELETE_NODE,
      vid: data.vid,
      payload: { deleteAllChildren: data.deleteAllChildren },
    })
  },
  async reset({ commit }) {
    commit('reset')

    await this.$db().model(ChatNode).deleteAll()
    await this.$db().model(StoryRevision).deleteAll()
  },
  async init({ commit, dispatch }, { storyId, langId, status = 'draft' }) {
    // Fetch the story details, it's revisions and the triggers
    await Promise.all([
      this.$db().model(Story).dispatch('fetchById', storyId),
      this.$db().model(Story).dispatch('setCurrent', storyId),
      this.$db().model(StoryRevision).dispatch('fetchByStoryId', storyId),
      this.$db().model(Trigger).dispatch('fetchByStoryId', storyId),
    ])

    // Get project info
    const { projectId } = this.$db().model(Story).getters('current')

    // Load the associated project to display the back button
    await Promise.all([
      this.$db().model(Project).dispatch('fetchById', projectId),
      this.$db().model(Project).dispatch('setCurrent', projectId),
    ])

    // load latest revision and its nodes
    const latestRevision = this.$db()
      .model(StoryRevision)
      .getters('lastRevisionByStoryId')(storyId, status)

    commit('setCurrentRevision', latestRevision)

    const nodes = langId
      ? await this.$db().model(Story).dispatch('fetchTranslateNodes', {
          storyId,
          langId,
          status,
        })
      : await dispatch('getChatNodesByRevisionId', latestRevision.id)

    commit('setGraph', new NodeGraph(nodes))

    commit('setStoryId', storyId)
    commit('setEditing', false)
    commit('resetNewNode')
    commit('resetShadowGraph')

    commit('softValidate')
  },
  getChatNodesByRevisionId({ state }, revisionId) {
    // TODO could look in cache first? but might be outdated

    return this.$db()
      .model(ChatNode)
      .dispatch('fetchByStoryRevisionId', revisionId)
  },

  async getTranslateNodes({ commit }, { storyId, langId, status = 'draft' }) {
    const nodes = await this.$db()
      .model(Story)
      .dispatch('fetchTranslateNodes', {
        storyId,
        langId,
        status,
      })

    commit('setGraph', new NodeGraph(nodes))
  },
  async enterEditMode({ state, commit, dispatch }, langId) {
    commit('setLoading', true)
    commit('setEditing', true)

    // when we publish the story , we unmount the preview component (ie. StoryBuilderConversationPreview) which atomatically destroys the chat bubble.
    // But window.aiaibot is remains present in the dom which prevent
    // chatbot from starting again (we have check in chatbot bootstrap function to not start the chatbot again if we have a window.aiaibot present in the dom).
    // Thats why we are deleting the current window.aiaibot so that it can be started again when the component StoryBuilderConversationPreview mounts

    // Before deleting window.aiaibot we are saving it on another global object which can be used in watch properties in Chatbot component to update current chatbot config.
    // We had to do save this aiaibotTemp because of closure that watch function in chatbot remember the old window.aiaibot whiich gets deleted eariler.

    if (window.aiaibot) {
      delete window.aiaibotTemp

      window.aiaibotTemp = {
        ...window.aiaibot,
      }
    }

    delete window.aiaibot

    const { storyId } = state

    await this.$db().model(StoryRevision).dispatch('fetchByStoryId', storyId)

    const activeChildWeightByVid = {}

    state.graph.nodes.forEach((node) => {
      activeChildWeightByVid[node.vid] = node.activeChildWeight
    })

    const existingDraftRevision = this.$db()
      .model(StoryRevision)
      .all()
      .find((revision) => revision.status === StoryRevision.STATUS_DRAFT)

    const nodes = await dispatch(
      'getChatNodesByRevisionId',
      existingDraftRevision.id,
    )

    commit('setCurrentRevision', existingDraftRevision)
    commit('setGraph', new NodeGraph(nodes))

    nodes.forEach((node) => {
      if (activeChildWeightByVid[node.vid]) {
        commit('setActiveChildWeightByVid', {
          vid: node.vid,
          weight: activeChildWeightByVid[node.vid],
        })
      }
    })

    commit('resetNewNode')
    commit('resetShadowGraph')

    commit('setLoading', false)
  },
  async exitEditMode({ state, commit, dispatch }) {
    commit('setLoading', true)
    commit('setEditing', false)

    const { storyId } = state

    const publishedRevision = this.$db()
      .model(StoryRevision)
      .getters('currentPublished')(storyId)

    commit('setCurrentRevision', publishedRevision)

    const activeChildWeightByVid = {}

    state.graph.nodes.forEach((node) => {
      activeChildWeightByVid[node.vid] = node.activeChildWeight
    })

    const nodes = await dispatch(
      'getChatNodesByRevisionId',
      publishedRevision.id,
    )

    commit('setGraph', new NodeGraph(nodes))

    nodes.forEach((node) => {
      if (activeChildWeightByVid[node.vid]) {
        commit('setActiveChildWeightByVid', {
          vid: node.vid,
          weight: activeChildWeightByVid[node.vid],
        })
      }
    })

    commit('resetNewNode')
    commit('resetShadowGraph')

    commit('setLoading', false)
  },
  async discardDraft({ state, commit, dispatch }, id) {
    commit('setLoading', true)

    const currentRevisionId = id || state.currentRevision?.id

    try {
      // only apiDelete draft if it exists on backend
      if (currentRevisionId) {
        await this.$db()
          .model(StoryRevision)
          .dispatch('apiDeleteRevision', currentRevisionId)
      } else if (state.currentRevision instanceof StoryRevision) {
        state.currentRevision.$delete()
      }

      commit('setEditing', false)
    } finally {
      commit('setLoading', false)
    }
  },
  async autosaveStory({ dispatch, commit, state }, { action, payload, vid }) {
    commit('softValidate')

    if (state.graph.errors.length > 0) {
      const $buefy = this._vm.$buefy
      const $t = this.$i18n.t.bind(this.$i18n)

      $buefy.notification.open({
        message: $t('error.story_builder.invalid_operation'),
        title: $t('error.story_builder.invalid_operation'),
        type: 'is-danger',
        duration: 6e3,
        'has-icon': true,
        'auto-close': false,
        position: 'is-bottom-right',
        class: 'float',
      })

      return true
    }

    try {
      if (action === ACTION_INSERT_NODE) {
        await dispatch('onActionInsertNode', payload)
      }

      if (action === ACTION_UPDATE_NODE) {
        await dispatch('onActionUpdateNode', { vid, payload })
      }

      if (action === ACTION_DELETE_NODE) {
        await dispatch('onActionDeleteNode', { vid, payload })
      }

      if (action === ACTION_MOVE_NODE) {
        const chatNode = state.graph.nodes.find(
          (node) => node.vid === payload.vid,
        )

        if (!chatNode) {
          return
        }

        // BE doesn't know about this chat node. We want to insert it instead of moving.
        if (chatNode.id === '') {
          await dispatch('onActionInsertNode', chatNode)

          return
        }

        await dispatch('onActionMoveNode', { vid, payload })
      }
    } catch (error) {
      const $buefy = this._vm.$buefy
      const $t = this.$i18n.t.bind(this.$i18n)

      // if error is network related, no internet, etc
      // NOTE this method won't detect ALL network errors
      if (!error?.response) {
        // TODO should we have a notification/error helper in vuex? this.$notify ?
        $buefy.notification.open({
          message: $t('error.storybuilder.changes_not_saved'),
          title: $t('error.network_error'),
          type: 'is-danger',
          duration: 6e3,
          'has-icon': true,
          'auto-close': false,
          position: 'is-bottom-right',
          class: 'float',
        })
      } else {
        // validation errors on autosave
        const glitch = new ErrorHandler(error, this.$i18n)
        const message = glitch.getNotificationString()

        $buefy.notification.open({
          message,
          title: null,
          type: 'is-danger',
          duration: 6e3,
          'has-icon': true,
          'auto-close': false,
          position: 'is-bottom-right',
          class: 'float',
        })
      }

      return error
    }
  },
  async onActionInsertNode(store, node) {
    const { dispatch, state, commit } = store
    const chatNode = await dispatch(
      'entities/story-revisions/saveChatNode',
      {
        storyId: state.storyId,
        node: node.getFormJson(),
      },
      {
        root: true,
      },
    )

    commit('updateNode', {
      vid: chatNode.vid,
      payload: { ...chatNode.payload, isDirty: false },
    })

    const n = state.graph.getNodeByVid(chatNode.vid, true)

    // set new node the id generated by backend
    commit('setNodeId', {
      node: n,
      id: chatNode.id,
    })

    const model = ChatElementFactory.getModelByType(node.payloadType).Model

    if (model.onAfterSave) {
      await Promise.all([model.onAfterSave.call(this, store, node)])
    }
  },
  async onActionUpdateNode(
    { state, commit, dispatch, getters },
    { vid, payload },
  ) {
    const data = {
      storyId: state.storyId,
      nodeVid: vid,
      payload,
    }

    await dispatch('entities/story-revisions/updateChatNode', data, {
      root: true,
    })
  },
  async onActionMoveNode(
    { state, commit, dispatch, getters },
    { vid, payload },
  ) {
    const data = {
      storyId: state.storyId,
      nodeVid: vid,
      payload,
    }

    await dispatch('entities/story-revisions/moveChatNode', data, {
      root: true,
    })
  },
  async onActionDeleteNode(
    { state, commit, dispatch, getters },
    { vid, payload },
  ) {
    const data = {
      storyId: state.storyId,
      nodeVid: vid,
      payload,
    }

    await dispatch('entities/story-revisions/deleteChatNode', data, {
      root: true,
    })
  },
  async publishRevision({ state, commit, dispatch, getters }) {
    commit('setLoading', true)
    commit('setEditing', false)

    const storyRevisions = await dispatch(
      'entities/story-revisions/apiPublish',
      state.storyId,
      {
        root: true,
      },
    )

    const graph = new NodeGraph(state.nodes || [])

    commit('setCurrentRevision', storyRevisions[0])
    commit('setGraph', graph)
    commit('resetNewNode')
    commit('resetShadowGraph')
    commit('setLoading', false)
  },

  setDropdownAutoClose({ commit }) {
    commit('setDropdownAutoClose')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
